<template>
  <v-container fluid>
    <v-card>
      <v-toolbar color="secondary" flat dense>
        <v-toolbar-title>Postdated Checks</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="accent" small to="/new-postdated-check">
          <v-icon left dark>mdi-plus</v-icon>New Postdated Check
        </v-btn>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-data-table :headers="headers"></v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Internal ID", value: "NumAtCard" },
        { text: "Deposit Currency", value: "CardName" },
        { text: "Bank Account", value: "" },
        { text: "Date", value: "" },
        { text: "Display Until", value: "" }
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
</style>